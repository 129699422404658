import Icon from 'components/Icon';
import React from 'react';
import { logRender } from 'utils/devLoggers';

import getYoutubeVideoThumb from '../utils/getYoutubeVideoThumb';

export interface VideoThumbProps {
  videoid: string;
  description: string;
}

function VideoThumb({ description, videoid }: VideoThumbProps) {
  logRender("PortfolioContentVideo: VideoThumb");

  const thumbPath = getYoutubeVideoThumb(videoid);
  return (
    <div className="carousel-thumb-container" key={videoid}>
      <img
        className="img-fluid img-responsive carousel-thumb-image "
        src={thumbPath}
        alt={description}
      />
      <Icon iconName="YoutubeIcon" />
    </div>
  );
}

export default React.memo(VideoThumb);
