import React from 'react';

import { IconDefinition, IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

// local icons are pre configured and dont allow the icon prop to be changed
export type LocalFAIconProps = Omit<FontAwesomeIconProps, 'icon'>;
export type LocalFAIcon = React.FC<LocalFAIconProps>;

interface Props {
  className?: string;
  icon: IconName | IconDefinition;
  spin?: boolean;
}

const FAIcon = ( { className = '', icon, spin = false, ...restProps }: Props ) => {
  // console.log(__filename, { icon, ...restProps });
  return (
    <FontAwesomeIcon style={ { width: '1em' } } icon={ icon } className={ `icon ${ className }` } spin={ spin } { ...restProps } />
  );
};

export default function makeFAIcon ( icon: IconDefinition ): LocalFAIcon {
  const LocalIcon: LocalFAIcon = ( props: LocalFAIconProps ) => <FAIcon icon={ icon } { ...props } />;
  return LocalIcon;
}
