/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import Form from 'react-bootstrap/Form';

interface BaseFieldProps {
  disabled?: boolean;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  title: string;
}
interface EmailFieldProps extends BaseFieldProps {}
interface MessageFieldProps extends BaseFieldProps {}
interface NameFieldProps extends BaseFieldProps {}

export const EmailField: React.FC<EmailFieldProps> = ({
  title,
  placeholder,
  handleChange,
  disabled,
}) => (
  <Form.Group>
    <Form.Label htmlFor='form-email' className='text-light'>
      {title}
    </Form.Label>

    <Form.Control
      required
      id='form-email'
      className='text-light'
      type='email'
      placeholder={placeholder}
      name='email'
      onChange={handleChange}
      disabled={disabled}
    />

    <Form.Control.Feedback type='valid' className='form-feedback'>
      Merci !
    </Form.Control.Feedback>

    <Form.Control.Feedback type='invalid' className='form-feedback'>
      Veuillez entrer une adresse électronique valide s&apos;il vous plait
    </Form.Control.Feedback>
  </Form.Group>
);

export const MessageField: React.FC<MessageFieldProps> = ({
  title,
  placeholder,
  handleChange,
  disabled,
}) => (
  <Form.Group>
    <Form.Label htmlFor='form-message' className='text-light'>
      {title}
    </Form.Label>

    <Form.Control
      required
      id='form-message'
      className='text-light'
      as='textarea'
      rows={3}
      placeholder={placeholder}
      name='message'
      onChange={handleChange}
      disabled={disabled}
    />

    <Form.Control.Feedback type='valid' className='form-feedback'>
      Merci !
    </Form.Control.Feedback>

    <Form.Control.Feedback type='invalid' className='form-feedback'>
      Veuillez préciser ce que vous avez besoin de savoir s&apos;il vous plait
    </Form.Control.Feedback>
  </Form.Group>
);

export const NameField: React.FC<NameFieldProps> = ({
  title,
  placeholder,
  handleChange,
  disabled,
}) => (
  <Form.Group>
    <Form.Label htmlFor='form-name' className='text-light'>
      {title}
    </Form.Label>

    <Form.Control
      required
      id='form-name'
      className='text-light'
      type='text'
      placeholder={placeholder}
      name='senderName'
      onChange={handleChange}
      disabled={disabled}
    />

    <Form.Control.Feedback type='valid' className='form-feedback'>
      Merci !
    </Form.Control.Feedback>

    <Form.Control.Feedback type='invalid' className='form-feedback'>
      Veuillez entrer votre nom s&apos;il vous plait
    </Form.Control.Feedback>
  </Form.Group>
);
