import clsx from 'clsx';
import Icon from 'components/Icon';
import React from 'react';
import { logRender } from 'utils/devLoggers';

interface Props {
  hasArrow: boolean;
  isPrev: boolean;
  label: string;
  onClickHandler: () => void;
}

// ? use react memo

const CarouselArrow = ({ onClickHandler, hasArrow, label, isPrev }: Props) => {
  logRender("CarouselArrow ");
  return (
    <>
      {hasArrow && (
        <div
          className={clsx("carousel-nav-button", isPrev ? "prev" : "next")}
          role="button"
          tabIndex={0}
          onClick={onClickHandler}
          title={label}
          onKeyUp={() => console.warn(__filename, `keyup handler tbc`)}
        >
          <Icon
            iconName={isPrev ? "ChevronLeftIcon" : "ChevronRightIcon"}
            inverse
          />
        </div>
      )}
    </>
  );
};

export default React.memo(CarouselArrow);
