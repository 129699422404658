import { ReCAPTCHA } from 'react-google-recaptcha';

import { ContactFormSubmitProps, HTMLContactFormElement } from '../declarations';
import contactFormDetailsValid from './contactFormDetailsValid';
import sendFormData from './sendFormData';

interface Props {
  captchaElementRef: React.RefObject<ReCAPTCHA>;
  formRef: React.RefObject<HTMLContactFormElement>;
  recaptchaResponse: string;

  sendEvent(event: any, payload?: any): unknown;
}

const handleSubmit = async ({
  recaptchaResponse,
  sendEvent,
  formRef,
  captchaElementRef,
}: Props) => {
  sendEvent("SUBMIT"); // let state machine know we are submitting

  if (!formRef.current) {
    sendEvent("ERROR", "form element ref not defined");
    return console.error(`formRef.current is not defined`);
  }

  // get form field elements
  const {
    senderName: nameField,
    email: emailField,
    message: messageField,
  } = formRef.current;

  // get values from elements
  const contactFormSubmitProps: ContactFormSubmitProps = {
    senderName: nameField.value,
    email: emailField.value,
    message: messageField.value,
    recaptchaResponse,
  };

  // double check required values are provided
  if (!contactFormDetailsValid(contactFormSubmitProps)) {
    return sendEvent("ERROR", "contact form details not valid");
  }

  const windowURL = typeof window !== "undefined" && window.location.href;

  // make sure window url exists
  if (!windowURL) {
    console.error(
      `Window url is not defined, unknown endpoint to use for functions`
    );
    return sendEvent("ERROR", "Window url is not defined");
  }

  // make sure captcha ref exists
  if (!captchaElementRef.current) {
    console.error(__filename, `captchaElementRef.current is not defined`);
    return sendEvent("ERROR", "captcha element ref not defined");
  }

  // todo extract this into a util, use node env to choose this automatically or window href
  // const endpoint = 'https://laura-sodaymay.netlify.app/.netlify/functions/send-form';
  // const endpoint = 'http://localhost:8888/.netlify/functions/send-form'; // ! for local testing
  // const endpoint = 'https://9dceb7f54dfe.ngrok.io/.netlify/functions/send-form';

  // create send-form endpoint from current url, add slashes if not defined
  const sendFormEndPoint = `${
    /localhost/gi.test(windowURL) ? "http://localhost:8888/" : windowURL
  }${/\/$/.test(windowURL) ? "" : "/"}.netlify/functions/send-form`;

  try {
    await sendFormData(contactFormSubmitProps, sendFormEndPoint);
    sendEvent("SENT");
  } catch (error) {
    console.error(__filename, "Error sending form data", { error });
    sendEvent("ERROR", "could not send form");
  } finally {
    captchaElementRef.current.reset(); // reset captcha as it is not reusable
  }
};

export default handleSubmit;
