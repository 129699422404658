import './Top.scss';

import IntroCardOverlay from 'components/IntroCardOverlay';
import { graphql } from 'gatsby';
// import useSmoothScrollTo from 'hooks/useSmoothScrollTo';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { BaseFrontMatter } from 'templates/top-index';
import { logRender } from 'utils/devLoggers';

export const fragment = graphql`
  fragment TopSection on Frontmatter {
    jumpToAnchor
    jumpToAnchorText
    quoteText
    quoteAuthor
  }
`;

export interface TopSectionFrontMatter extends BaseFrontMatter {
  jumpToAnchor: string;
  jumpToAnchorText: string;
  quoteAuthor: string;
  quoteText: string;
}

interface Props {
  frontmatter: TopSectionFrontMatter;
}

const Top = ({ frontmatter }: Props) => {
  logRender("Top");
  if (!frontmatter) return null;

  const {
    header,
    subheader,
    jumpToAnchor,
    jumpToAnchorText,
    quoteText,
    quoteAuthor,
  } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // ? is this required
  // const scrollToSection = useSmoothScrollTo( jumpToAnchor );

  let extraInfoPart;
  if (jumpToAnchor && jumpToAnchorText) {
    extraInfoPart = (
      <Link
        className='nav-link cursor-pointer'
        activeClass='active'
        to={jumpToAnchor}
        spy
        isDynamic
        offset={-70}
        smooth='easeInOutQuart'
      >
        <Button
          size='xl'
          variant='primary'
          className='text-uppercase btn-primary'
        >
          {jumpToAnchorText}
        </Button>
      </Link>
    );
  }

  return (
    <Card className='top-section image-card bg-transparent text-white text-center'>
      <IntroCardOverlay
        header={header}
        subheader={subheader}
        extraInfo={extraInfoPart}
        quoteText={quoteText}
        quoteAuthor={quoteAuthor}
      />
    </Card>
  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
};

Top.defaultProps = {
  frontmatter: null,
};

export default Top;
