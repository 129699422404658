module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Laura Sodaymay Portfolio","short_name":"Laura Sodaymay","start_url":"/","background_color":"#333","theme_color":"#34c3ab","display":"minimal-ui","icon":"/opt/build/repo/static/content/assets/White-logo-with-black-bg.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1b5fbeaa873519958ff9b1e2f59381c5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/opt/build/repo/src/cms/cms.js","enableIdentityWidget":true,"publicPath":"admin","htmlTitle":"LS Portfolio Content Manager","htmlFavicon":"/opt/build/repo/static/content/assets/White-logo-with-black-bg.webp","includeRobots":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
