import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Icon from "components/Icon";

import { Fade } from "react-awesome-reveal";

import "./TimelineItem.scss";

// todo convert to ts
const TimelineItem = ({
  invert,
  // todo remove image from timeline item
  imageFileName,
  imageAlt,
  imageContent,
  header,
  subheader,
  content,
  contentPoints,
  location,
  position,
  iconName,
}) => {
  const positionPart = position && (
    <h3 className='position-text text-primary'> {position} </h3>
  );
  const headerPart = header && <h4 className='text-primary'> {header} </h4>;
  const subheaderPart = subheader && (
    <h4 className='subheading'> {subheader} </h4>
  );

  const locationPart = location && (
    <h5 className='location-text '> {location} </h5>
  );

  const contentPart = content && <p className='text-light'> {content} </p>;
  const contentPointsPart = contentPoints && (
    <Fade duration={500} cascade>
      {contentPoints.map((e) => (
        <li key={e.text}> {e.text} </li>
      ))}
    </Fade>
  );

  const liClassName = clsx("timeline-item", {
    "timeline-inverted": invert,
  });

  // ignore text only timeline items for now, styling tbc, TODO
  if (imageContent) return null;

  // console.log("TimelineItem header", header, "contentPoints:", contentPoints);

  return (
    <li className={liClassName}>
      <div>
        {imageContent ? (
          <div className='image-content'> {imageContent} </div>
        ) : (
          <div className='timeline-image'>
            <Icon
              className='timeline-icon rounded-circle img-fluid'
              iconName={iconName}
            />
          </div>
        )}
      </div>
      <div className='timeline-panel'>
        <div className='timeline-heading'>
          {positionPart} {subheaderPart} {headerPart} {locationPart}
        </div>
        <div className='timeline-body'>
          {contentPart} {contentPoints && <ul> {contentPointsPart} </ul>}
        </div>
      </div>
    </li>
  );
};

TimelineItem.propTypes = {
  invert: PropTypes.bool,
  imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  imageContent: PropTypes.any,
  header: PropTypes.string,
  subheader: PropTypes.string,
  content: PropTypes.string,
  contentPoints: PropTypes.array,
  location: PropTypes.string,
  position: PropTypes.string,
  iconName: PropTypes.string,
};

TimelineItem.defaultProps = {
  invert: false,
  imageFileName: "",
  imageAlt: "",
  imageContent: null,
  header: "",
  subheader: "",
  content: "",
  contentPoints: null,
  location: "",
  position: "",
  iconName: null,
};

export default TimelineItem;
