import React from 'react';

const WeirdShape = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.76 644" style={{ width: '100%', height: '100%' }}>
			<path
				fill="#01b0b8"
				stroke="#01b0b8"
				d="M90.63,580.48A706.83,706.83,0,0,1,37.77,472.13c-18-46.77-35.37-91.78-37-153.29C.11,294.06-.51,258.6,14,215.76c8-23.58,28.88-85.35,84.58-117.61,13-7.5,37.16-18.83,66.07-26.42,23.84-6.27,36.71-6.27,59.47-17.18,2.87-1.38,4.81-2.41,35.67-21.15C298.86,9.68,300,8.77,304.7,7c3.64-1.37,37.61-13.85,67.4,0C413.32,26.14,422.29,82.75,425,99.48c5.31,33.3-3.57,43.8,5.28,91.18,4.33,23.17,9.66,37.78,11.89,43.6,6.05,15.76,13.61,30.17,30.4,55.5,26.13,39.44,31.08,39.67,41.62,57.49,34,57.38,23.15,123.66,19.83,144a250.49,250.49,0,0,1-21.15,67.39c-36.11,49.18-68.89,62.87-92.5,66.07-27.32,3.7-82.88-1.15-107-4a127.54,127.54,0,0,0-35.68,0c-21.15,3.13-30.44,10.32-46.25,15.86-1.51.52-37.5,12.7-72,3.3C118.78,628.91,97.2,592.69,90.63,580.48Z"
			/>
		</svg>
	);
};
// "stroke-miterlimit": 10

export default WeirdShape;
