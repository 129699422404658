/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Carousel from 'components/Carousel/Carousel';
// import Icon from 'components/Icon';
// import md2html from 'utils/md2html';
import Image from 'components/Image';
// import { Col, Row } from 'react-bootstrap';
import { graphql } from 'gatsby';
import React from 'react';
import { logRender } from 'utils/devLoggers';

import { RenderThumbs } from './Carousel/declarations/types';

export const fragment = graphql`
  fragment PortfolioContentGallery on MarkdownRemarkFrontmatterPortfolios {
    galleryImages {
      imageFileName
      imageTitle
    }
  }
`;

export interface GalleryImageFrontmatter {
  imageFileName: string;
  imageTitle: string;
}

interface Props {
  galleryImages: GalleryImageFrontmatter[];
}
// todo memoise thumbs
// todo split this into distinct parts
const PortfolioContentGallery = ({ galleryImages }: Props) => {
  logRender(`${__filename}`);
  // console.log(__filename, { galleryImages });

  const renderThumbsJSX: RenderThumbs = (children) => {
    logRender(`${__filename}:renderThumbs`);
    return (
      children &&
      children.map(({ props }) => (
        <div
          className="carousel-thumb-container"
          key={props["data-thumbimagefilename"]}
        >
          <Image
            className="img-fluid img-responsive carousel-thumb-image "
            fileName={props["data-thumbimagefilename"]}
            alt={props["data-alt"]}
          />
        </div>
      ))
    );
  };

  const slidesJSX =
    galleryImages &&
    galleryImages.map(({ imageFileName, imageTitle }) => (
      <div
        className="col carousel-item-container"
        key={imageFileName}
        data-alt={imageTitle}
        data-thumbimagefilename={imageFileName}
      >
        <Image
          className="img-fluid row no-gutters carousel-image"
          fileName={imageFileName}
          alt={imageTitle}
        />
      </div>
    ));

  return (
    galleryImages && (
      <Carousel renderThumbs={renderThumbsJSX}>{slidesJSX}</Carousel>
    )
  );
};

export default PortfolioContentGallery;
