import './Portfolio.scss';

import clsx from 'clsx';
import PageSection from 'components/PageSection';
import PortfolioItem from 'components/PortfolioItem';
import SectionHeader from 'components/SectionHeader';
import { graphql } from 'gatsby';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { BaseFrontMatter } from 'templates/top-index';
import { logRender } from 'utils/devLoggers';

export const fragment = graphql`
  fragment PortfolioSection on Frontmatter {
    portfolios {
      contentMarkdown
      extraInfo
      header
      subheader
      imageFileNameDetail
      imageFileName
      ...PortfolioContent
    }
  }
`;

export interface PortfolioSectionFrontmatter extends BaseFrontMatter {
  portfolios: PortfoliosProps[];
}

export interface PortfoliosProps {
  contentMarkdown?: string;
  extraInfo?: any;
  header: string;
  imageFileName: string;
  imageFileNameDetail?: string;
  subheader?: string;
}

interface Props {
  className?: string;
  frontmatter?: PortfolioSectionFrontmatter;
}

export default function Portfolio({ className, frontmatter }: Props) {
  logRender("Portfolio");
  const [expandedKey, setExpandedKey] = React.useState("");

  if (!frontmatter) {
    console.error(__filename, "Frontmatter is", { frontmatter });
    return null;
  }

  const {
    header: rootHeader,
    subheader: rootSubHeader,
    portfolios,
  } = frontmatter;

  if (!rootHeader)
    console.error(__filename, `Root header is not defined, it should be!`);

  return (
    <PageSection className={clsx("portfolio-section", className)}>
      <Row>
        <SectionHeader
          header={rootHeader || "Root Header Fallback"}
          subheader={rootSubHeader}
        />
      </Row>
      <Row noGutters>
        {portfolios.map(({ header, ...restProps }) => (
          <PortfolioItem
            key={header}
            itemKey={header}
            setExpandedKey={setExpandedKey}
            expanded={expandedKey === header}
            header={header}
            {...restProps}
          />
        ))}
      </Row>
    </PageSection>
  );
}
