// advice from https://web.dev/adaptive-serving-based-on-network-quality/

import React, { useEffect } from 'react';

export default function useNetworkType(): EffectiveConnectionType {
  const [networkType, setNetworkType] =
    React.useState<NetworkInformation["effectiveType"]>("slow-2g");

  useEffect(() => {
    if (typeof window === "undefined") return;

    const navigatorConnection = window.navigator.connection;

    const changeHandler = () =>
      setNetworkType(
        (current) => window?.navigator?.connection?.effectiveType || current
      );
    navigatorConnection.addEventListener("change", changeHandler);

    setNetworkType(navigatorConnection.effectiveType);

    return () =>
      navigatorConnection.removeEventListener("change", changeHandler);
  }, []);

  return networkType || "slow-2g";
}
