import React from "react";
import PropTypes from "prop-types";
// import Carousel from "react-bootstrap/Carousel";
import Row from "react-bootstrap/Row";
import { graphql } from "gatsby";

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import "./Testimonies.scss";
import { logRender } from "utils/devLoggers";

export const fragment = graphql`
  fragment TestimoniesSection on Frontmatter {
    teamMember {
      header
      subheader
      imageFileName
      company
      position
      location
      quote
      social {
        facebook
        github
        linkedin
        medium
        twitter
        email
      }
    }
  }
`;

const Testimonies = ({ className, frontmatter }) => {
  logRender("Testimonies");
  if (!frontmatter) {
    console.error(__filename, "Frontmatter is", { frontmatter });
    return null;
  }

  // console.log("Testimonies", "testimony pre-render");

  const { header: rootHeader, subheader: rootSubHeader } = frontmatter;

  /*
Image used to be in carousel
  <Image
                className="mx-auto circle rounded-circle"
                fileName={imageFileName}
                alt={imageAlt || header || subheader}
              />
  */

  // TODO update this to not use bootstrap carousel

  return (
    <PageSection className={`${className} testimonies-section`}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>

      <Row>
        <h2>TURNED OFF, ASK ELI TO TURN IT ON</h2>
        {/*
        <Carousel interval={3000}>
          {teamMember.map(
            ({
              imageFileName,
              header,
              imageAlt,
              subheader,
              company,
              position,
              location,
              quote
            }) => (
              // social: { twitter, facebook, linkedin, github, medium },
              <Carousel.Item key={header}>
                <div className="highlight-text">
                  <Icon
                    iconName="QuoteLeftIcon"
                    size="2x"
                    className="m-1 text-primary"
                  />
                  <span>{subheader}</span>

                  <Icon
                    iconName="QuoteRightIcon"
                    size="2x"
                    className="m-1  text-primary"
                  />
                </div>

                <h3 className="name-text">{header}</h3>

                <Row className="m-0">
                  <Col lg={6}>
                    <Row className="align-items-center">
                      <Col sm={2} className="icon-col">
                        <Icon
                          iconName="UsersIcon"
                          size="2x"
                          className="m-1 testimony-icon"
                        />
                      </Col>

                      <Col sm={10} className="pl-0">
                        <p className="position-text">{position}</p>
                      </Col>
                    </Row>

                    <Row className="align-items-center">
                      <Col sm={2} className="icon-col">
                        <Icon
                          iconName="BuildingIcon"
                          size="2x"
                          className="m-1 testimony-icon"
                        />
                      </Col>

                      <Col sm={10} className="pl-0">
                        <p className="company-text">{company}</p>
                      </Col>
                    </Row>

                    <Row className="align-items-center">
                      <Col sm={2} className="icon-col">
                        <Icon
                          iconName="LocationIcon"
                          size="2x"
                          className="m-1 testimony-icon"
                        />
                      </Col>

                      <Col sm={10} className="pl-0">
                        <p className="location-text">{location}</p>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={6} className="align-items-start">
                    <Row>
                      <Col sm={2} className="icon-col align-items-start">
                        <Icon
                          iconName="QuoteLeftIcon"
                          size="2x"
                          className="m-1 testimony-icon"
                        />
                      </Col>

                      <Col sm={10} className="pl-0">
                        <p className="quote-text">&quot;{quote}&quot;</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Carousel.Item>
            )
          )}
        </Carousel>
      */}
      </Row>
    </PageSection>
  );
};

Testimonies.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Testimonies.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Testimonies;
