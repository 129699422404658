import React from 'react';

export interface DelayedVideoProps {
  autoPlay?: boolean;
  className?: string;
  delayMs?: number;
  poster: string;
  srcMp4: string;
}

export default function LazyVideo({
  srcMp4,
  poster,
  className = "",
  ...restProps
}: DelayedVideoProps) {
  return (
    <video
      className={className}
      poster={poster}
      preload='auto'
      autoPlay
      muted
      loop
      playsInline
      {...restProps}
    >
      <source src={srcMp4} type='video/mp4' />
      video not supported
    </video>
  );
}
