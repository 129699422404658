import CircleIcon from 'components/CircleIcon';
import React from 'react';

interface Props {
  userName: string;
}

export default function Github({ userName }: Props) {
  return (
    <CircleIcon href={`https://github.com/${userName}`} iconName="GithubIcon" />
  );
}
