import React from "react";
import PropTypes from "prop-types";

import { Slide } from "react-awesome-reveal";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import LsLogo from "components/LsLogo";
import "./IntroCardOverlay.scss";

const IntroCardOverlay = ({
  className,
  header,
  subheader,
  extraInfo,
  quoteText,
  quoteAuthor
}) => {
  const quoteTextJSX =
    quoteText &&
    quoteText.split(" ").map((e) => (
      <div key={e} className="intro-quote-textx">
        {e}
      </div>
    ));

  return (
    <Card.ImgOverlay className="no-padding intro-card-overlay">
      <Container fluid>
        <div className="intro-text">
          <Slide delay={0} direction="up" triggerOnce>
            <div className="intro-heading text-uppercaseX text-shadow">
              {header}
            </div>
          </Slide>
          <LsLogo />
          <Slide delay={600} direction="up" triggerOnce>
            {extraInfo}
          </Slide>
        </div>
      </Container>
    </Card.ImgOverlay>
  );
};

IntroCardOverlay.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  extraInfo: PropTypes.any,
  quoteText: PropTypes.string,
  quoteAuthor: PropTypes.string
};

IntroCardOverlay.defaultProps = {
  className: null,
  header: "",
  subheader: "",
  extraInfo: null,
  quoteText: "",
  quoteAuthor: ""
};

export default IntroCardOverlay;
