// test to see if url includes "admin" to know if its CMS preview
const isCmsUrl = (url: string): boolean => /.+\/admin\/#\/.+/g.test(url);

export default function isCMS(): boolean {
  // test to see if this is a real site being generated on server
  if (typeof window === "undefined") return false;
  const pageUrl = window.location.href;
  const result = isCmsUrl(pageUrl);
  return result;
}
