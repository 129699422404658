import { FrontmatterUnion } from 'declarations/types';
import { SectionMarkdownFields, SectionMarkdownNode } from 'templates/top-index';
import { FooterFrontmatter } from 'views/Footer/Footer';
import { NavBarFrontMatter } from 'views/Navbar/Navbar';
import { TopSectionFrontMatter } from 'views/Top/Top';

// todo add test

/**
 * A higher order function to produce a predicate function that checks if a given node has a given field
 * and whether it matches a given regex
 * @param regEx Regex to test the property with, if it exists
 */
const filterNodesByField = <F extends FrontmatterUnion = FrontmatterUnion>(
  fieldName: keyof SectionMarkdownFields,
  regEx: RegExp
) => {
  // returns a predicate function which tests the given regex on the given field
  return (node: SectionMarkdownNode<F>): node is SectionMarkdownNode<F> =>
    regEx.test(node.fields[fieldName]);
};

/**
 * A higher order function to produce a predicate function that checks if a given node has a given frontmatter property
 * and whether it matches a given regex or value
 * @param criteria Regex or value to test the property with, if it exists
 */
const filterNodesByFrontmatter = <F extends FrontmatterUnion>(
  fieldName: keyof FrontmatterUnion,
  criteria: RegExp | string | number | boolean
) => {
  // returns a predicate function which tests the given regex on the given field
  return (node: SectionMarkdownNode<F>): node is SectionMarkdownNode<F> => {
    const value = node.frontmatter[fieldName];

    /*
    const outputObject = {
      nodeFields: node.fields,
      fieldName,
      value,
      criteria,
    };
    */

    // if regex is given, test it otherwise check for value equivalence
    if (typeof criteria === "object") {
      return (
        criteria.test(value || "") ||
        /* console.error(
          __filename,
          `Filtering out node because frontmatter property "${fieldName}" does not match regex /${criteria.source}/${criteria.flags}`,
          outputObject
        ) || */
        false
      );
    }

    // hidden is sometimes null so just check for nullish values
    if (fieldName === "hidden") {
      return (
        !value ||
        /* console.error(
          __filename,
          `Filtering out node because frontmatter property "${fieldName}" is truthy`,
          outputObject
        ) || */
        false
      );
    }

    // otherwise just compare values
    return (
      criteria === node.frontmatter[fieldName] ||
      /* console.error(
        __filename,
        `Filtering out node because frontmatter property "${fieldName}" does not match criteria "${String(
          criteria
        )}"`,
        outputObject
      ) || */
      false
    );
  };
};

/**
 * processes raw markdown nodes
 */
export default function breakDownAllNodes(nodes: SectionMarkdownNode<any>[]) {
  // todo input nodes should really be any type then there should be a predicate to confirm they are what they should be

  const topNode = nodes.find(
    filterNodesByField<TopSectionFrontMatter>("fileName", /top/i)
  );
  const navBarNode = nodes.find(
    filterNodesByField<NavBarFrontMatter>("fileName", /navbar/i)
  );
  const footerNode = nodes.find(
    filterNodesByField<FooterFrontmatter>("fileName", /footer/i)
  );

  const sectionsNodes: SectionMarkdownNode[] = nodes
    .filter(filterNodesByField("directoryName", /sections/i))
    .filter(filterNodesByFrontmatter("hidden", false)); // filter out sections explicitly marked as hidden

  // anchors for NavBar
  const anchors = sectionsNodes
    .map((node) => node.frontmatter.anchor)
    .filter((anchor) => {
      /*
      console.warn(__filename, `Evaluating anchor ${String(anchor)}`, {
        anchor,
        typeofAnchor: typeof anchor,
      });
      */
      return (
        (typeof anchor === "string" && anchor !== "") || null
        /* console.error(
          __filename,
          `Filtering out anchor ${String(anchor)} because it is not a string`
        ) */
      );
    })
    .map((anchor) => String(anchor)); // make sure they are strings

  // make sure specific nodes were found
  if (!topNode) throw Error(`topNode not found`);
  if (!navBarNode) throw Error(`navBarNode not found`);
  if (!footerNode) throw Error(`footerNode not found`);

  return {
    topNode,
    navBarNode,
    footerNode,
    sectionsNodes,
    anchors,
  };
}
