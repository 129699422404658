import populateImagesMap from 'components/Image/utils/populateImagesMap';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import BootstrapImage from 'react-bootstrap/Image';
import isCMS from 'utils/isCMS';

import { QuestionCircleIcon } from '../SystemIcons';
import { BaseImageProps, HasFileName, HasSrc, ImageQueryData } from './declarations/interfaces';
import { ImageEdgesMap } from './declarations/types';

const imagesMap: ImageEdgesMap = new Map();

interface ImageCMSProps extends BaseImageProps, HasSrc {}

// todo try to use gatsby staticImage instead of raw loading
/** Raw image file from a given path, for use in CMS environment where Gatsby image processing is not available */
const ImageRaw = ({ src, alt, ...restProps }: ImageCMSProps) => (
  <div {...restProps}>
    <BootstrapImage
      fluid
      className='img-fluid'
      style={{
        objectFit: "contain",
        objectPosition: "center center",
        height: "auto",
      }}
      src={src}
      alt={alt}
    />
  </div>
);

interface ImageLiveProps extends BaseImageProps, HasSrc {}

/** Gatsby processed image, for use on live site */
const ImageGatsby = ({ src, alt, ...restProps }: ImageLiveProps) => {
  const data: ImageQueryData = useStaticQuery(graphql`
    {
      allFile(filter: { ext: { in: [".jpeg", ".jpg", ".png", ".webp"] } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 75
              )
            }
          }
        }
      }
    }
  `);

  // remove the fixed parts of the path that refer to the base images folder
  const relativePath = src.replace("/content/assets/images/", "");

  // populate images map for quick access to images
  if (!imagesMap || !imagesMap.size) populateImagesMap(data, imagesMap);

  // get image node from map if it exists
  const imageEdge = imagesMap.get(relativePath);

  if (!imageEdge || !imageEdge.node) {
    console.error(__filename, "Image edge not found", {
      src,
      relativePath,
      imageEdge,
    });
    return null;
  }

  const imageData = getImage(imageEdge.node);

  if (!imageData) {
    console.error(__filename, "Could not get image data", { imageEdge });
    return null;
  }

  // ! do not apply imgStyle={{ objectFit: 'contain' }} here, some utilisations rely on it being off
  return <GatsbyImage alt={alt} image={imageData} {...restProps} />;
};

interface ImageProps extends BaseImageProps, HasFileName {}

export default function Image(props: ImageProps) {
  const { fileName, ...imageProps } = props;

  let staticFilePath = fileName;

  // format cms paths
  if (fileName.startsWith("/static")) {
    staticFilePath = fileName.replace("/static", "");
  } else if (!fileName.startsWith("/")) {
    staticFilePath = "/content/assets/images/" + fileName;
  } else {
    console.error(__filename, "Unknown filename format", { fileName });
    return <QuestionCircleIcon />;
  }

  const outProps = { ...imageProps, src: staticFilePath };

  return isCMS() ? <ImageRaw {...outProps} /> : <ImageGatsby {...outProps} />;
}
