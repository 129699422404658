import 'utils/fixFontAwesome';
import '../style/global.scss';

import Loading from 'components/Loading';
import { FrontmatterUnion } from 'declarations/types';
// import PageBody from 'components/PageBody';
import { graphql } from 'gatsby';
import DefaultLayout from 'layouts/DefaultLayout';
import React from 'react';
// import LanguageSelector from 'components/LanguageSelector';
import breakDownAllNodes from 'utils/breakDownAllNodes';
import Footer from 'views/Footer';
import Navbar from 'views/Navbar';
import Top from 'views/Top';

import loadable from '@loadable/component';

export interface BaseFrontMatter {
  anchor?: string;
  content?: string;
  contentMarkdown?: string;
  fileName?: string;
  header?: string;
  hidden?: string;
  imageFileName?: string;
  subheader?: string;
}

// todo try sorting by built in file path field
export const query = graphql`
  query IndexQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { hidden: { ne: true } }
        fields: { langKey: { eq: $langKey } }
      }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          hidden
          header
          subheader
          anchor
          content
          contentMarkdown
          imageFileName
          fileName
          ...NavBarSection
          ...TopSection
          ...PortfolioSection
          ...ServicesSection
          ...AboutSection
          ...TestimoniesSection
          ...ContactSection
          ...FooterSection
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

interface Props {
  data: Data;
  pageContext: PageContext;
}

// todo relocate
export type LanguageKeyUnion = "fr" | "en";

export interface PageContext {
  defaultLang: LanguageKeyUnion;
  langKey: LanguageKeyUnion;
  langTextMap: any;
}

export interface SectionMarkdownFields {
  directoryName: string;
  fileName: string;
}

export interface SectionMarkdownNode<
  F extends FrontmatterUnion = FrontmatterUnion
> {
  fields: SectionMarkdownFields;
  frontmatter: F;
}

interface SiteData {
  description?: string;
  keywords?: string[];
}

interface Data {
  allMarkdownRemark: { nodes: SectionMarkdownNode[] };
  site: { siteMetadata: SiteData };
}

export default function IndexPage({ data, pageContext }: Props) {
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    allMarkdownRemark: { nodes },
  } = data;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { langKey = "fr", defaultLang = "fr", langTextMap = {} } = pageContext;
  const { topNode, navBarNode, anchors, footerNode, sectionsNodes } =
    breakDownAllNodes(nodes);

  // load page body as a separate chunk
  const PageBody = loadable(
    () =>
      new Promise((resolve) => setTimeout(resolve, 2000)).then(
        () => import(`../components/PageBody`)
      ),
    {
      fallback: <Loading />,
    }
  );

  return (
    <DefaultLayout
      langKey={langKey}
      keywords={keywords}
      description={description}
    >
      <Navbar
        anchors={anchors}
        frontmatter={navBarNode.frontmatter}
        pageContext={pageContext}
      />
      <Top frontmatter={topNode.frontmatter} />
      <PageBody sectionsNodes={sectionsNodes} />
      <Footer frontmatter={footerNode.frontmatter} />
    </DefaultLayout>
  );
}
