import Carousel from "components/Carousel/Carousel";
import Icon from "components/Icon";
import Image from "components/Image";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import md2html from "utils/markdownToHtml";

import "./PortfolioContentArticles.scss";

export const fragment = graphql`
  fragment PortfolioContentArticles on MarkdownRemarkFrontmatterPortfolios {
    articles {
      title
      contentMarkdown
      links {
        url
        iconName
        imageTitle
        imageFileName
      }
    }
  }
`;

// todo memoise thumbs
// todo split this into distinct parts
const PortfolioContentArticles = ({ articles, className, ...restProps }) => {
  const renderArticleThumbsJSX = (children) => {
    return (
      children &&
      children.map((e, i) => (
        <div className='carousel-thumb-container' key={e.props.thumbkey}>
          <Icon className='' iconName={e.props.thumbiconname} />
        </div>
      ))
    );
  };

  const articlesJSX = articles.map((article) => {
    const slidesJSX = article.links.map((link) => (
      <div
        key={link.url}
        className='col p-0 article-icon-container carousel-item-container'
        thumbiconname={link.iconName}
        thumbkey={link.url}
      >
        <a
          href={link.url}
          target='_blank'
          rel='noopener noreferrer'
          aria-label={link.iconName}
        >
          <div className='article-corner' />
          <Icon className='article-icon' iconName={link.iconName} />
          <Image
            className='img-fluid img-responsive carousel-image '
            fileName={link.imageFileName}
            alt={link.imageFileName}
          />
        </a>
      </div>
    ));

    return (
      <Col
        md={true}
        key={article.title}
        className={"article-container" + className}
        {...restProps}
      >
        <h5>{article.title}</h5>
        <div
          className='article-content'
          dangerouslySetInnerHTML={{ __html: md2html(article.contentMarkdown) }}
        />
        <Carousel renderThumbs={renderArticleThumbsJSX}>{slidesJSX}</Carousel>
      </Col>
    );
  });

  return articles && <Row className='articles'>{articlesJSX}</Row>;
};

PortfolioContentArticles.propTypes = {
  articles: PropTypes.array.isRequired,
  className: PropTypes.string,
};

PortfolioContentArticles.defaultProps = {
  className: "",
};

export default PortfolioContentArticles;
