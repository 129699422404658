/* eslint-disable @typescript-eslint/indent */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  description?: string;
  isCms?: boolean;
  keywords?: string[];
  lang?: string;
  meta?: any[];
  title: string;
}

const SEO = ({
  lang = "fr",
  description = "Description TBC",
  meta = [],
  keywords = [],
  title,
}: Props) => {
  const { site } = useStaticQuery<{
    site?: {
      siteMetadata?: { description?: string; title?: string; author?: string };
    };
  }>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  if (!site || !site.siteMetadata) {
    console.error("site metaData not defined", { site });
    return null;
  }

  // ? why is description coming from 2 places?
  const metaDescription =
    description || site.siteMetadata.description || "site description TBC";
  const metaTitle = site.siteMetadata.title || "site title TBC";
  const metaAuthor = site.siteMetadata.author || "site author TBC";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${metaTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  );
};

const SeoProxy: React.FC<Props> = (props) => {
  const { isCms } = props;
  // check must be done before static query for CMS
  if (isCms) return null;
  return <SEO {...props} />;
};
export default SeoProxy;
