import CircleIcon from 'components/CircleIcon';
import React from 'react';

interface Props {
  userName: string;
}
export default function Facebook({ userName }: Props) {
  return (
    <CircleIcon
      href={`https://facebook.com/${userName}`}
      iconName="FacebookIcon"
    />
  );
}
