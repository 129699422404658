import React from "react";

import { scroller, animateScroll } from "react-scroll";

const DefOptions = {
  smooth: "easeInOutQuart",
};

const useSmoothScrollTo = (anchorOrPosition, options = {}) => {
  const handleScrollTo = React.useCallback(() => {
    const opts = { ...DefOptions, ...options };
    switch (typeof anchorOrPosition) {
      case "number":
      case "bigint":
        animateScroll.scrollTo(anchorOrPosition, opts);
        break;
      case "string":
        scroller.scrollTo(anchorOrPosition, opts);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorOrPosition]);

  return handleScrollTo;
};

export default useSmoothScrollTo;
