/* eslint-disable no-console */

export function logEventIfDev(
  type: "RENDER" | "FUNCTION CALL",
  name: string,
  data?: any
) {
  if (process.env.NODE_ENV !== "development") return;

  console.log(type, {
    name,
    time: new Date().toLocaleTimeString(),
    data,
  });
  console.count(`${type}: ${name}`);
}

export function logRender(name: string, data?: any): void {
  logEventIfDev("RENDER", name, data);
}

export function logFunctionCall(name: string, data?: any): void {
  logEventIfDev("FUNCTION CALL", name, data);
}

export default {};
