import './Navbar.scss';

import LogoImg from 'assets/Blanc2-512x512px.webp';
import clsx from 'clsx';
import Icon from 'components/Icon';
import NavItem from 'components/NavItem';
import { graphql } from 'gatsby';
import useShowNavbarFooterOnScroll from 'hooks/useShowNavbarFooterOnScroll';
import useSmoothScrollTo from 'hooks/useSmoothScrollTo';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { PageContext } from 'templates/top-index';
import { logRender } from 'utils/devLoggers';

export const fragment = graphql`
  fragment NavBarSection on Frontmatter {
    brand
    menuText
  }
`;

export interface NavBarFrontMatter {
  brand: string;
  menuText: string;
}

interface Props {
  anchors?: string[];
  frontmatter: NavBarFrontMatter;
  isCms?: boolean;
  pageContext: PageContext;

  // todo specify pageContext type
}

function MyNavbar({
  anchors = ["Link 1", "Link 2", "Link 3"],
  frontmatter,
  isCms,
}: Props): React.ReactElement {
  logRender("MyNavbar");
  const { brand, menuText } = frontmatter;
  // const { langKey, defaultLang, langTextMap } = pageContext || {};

  const languageSelector = null;
  // TODO fix english side to allow use of language selector
  /*
  if (langTextMap != null && Object.keys(langTextMap).length > 1) {
    langSelectorPart = (
      <LanguageSelector langKey={langKey} defaultLang={defaultLang} langTextMap={langTextMap} />
    );
  }
  */

  const handleScrollToTop = useSmoothScrollTo(0);

  // React state variables
  const [expanded, setExpanded] = React.useState(false);
  // const [showScrollUpFAB, setShowScrollUpFAB] = React.useState(false);

  // React callbacks
  const toggleMenu = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const closeMenu = React.useCallback(() => {
    setExpanded(false);
  }, []);

  const handleBrandClick = React.useCallback(() => {
    closeMenu();
    handleScrollToTop();
  }, [closeMenu, handleScrollToTop]);

  // todo extract this to a hook to be used with navbar and footer
  const showNavbar = useShowNavbarFooterOnScroll();

  return (
    <Navbar
      className={clsx("navbar-root", { "navbar-shrink": showNavbar || isCms })}
      expand='xl'
      fixed='top'
      expanded={expanded}
    >
      <Container fluid className='display-relative'>
        <Navbar.Brand
          id='brand'
          className='cursor-pointer'
          onClick={handleBrandClick}
        >
          <div className='logo'>
            <img className='img-fluid' src={LogoImg} alt='LS Logo' />
          </div>
          <span className=''>{brand}</span>
        </Navbar.Brand>

        <Navbar.Toggle
          onClick={toggleMenu}
          aria-label='Toggle navigation'
          className='btn-primary row no-gutters'
        >
          <span>{menuText}</span>
          <Icon iconName='BarsIcon' />
        </Navbar.Toggle>

        <Navbar.Collapse>
          <Nav className='ml-auto'>
            {anchors.map((anchor) => (
              <NavItem
                shrink={!showNavbar}
                key={anchor}
                to={anchor}
                onClick={closeMenu}
              />
            ))}
          </Nav>
          {languageSelector}
        </Navbar.Collapse>
      </Container>

      <div className='line bg-gradient-primary' />
    </Navbar>
  );
}

export default React.memo(MyNavbar);
