/* eslint-disable @typescript-eslint/restrict-template-expressions */
export default function loadDOMScript(url) {
  if (!document) return console.error("loadDOMScript", "document is falsy");

  try {
    const scriptElem = document.createElement("script");
    scriptElem.src = url;
    scriptElem.async = true;
    document.head.appendChild(scriptElem);
    return null;
  } catch (error) {
    console.error(__filename, { error });
    return new Error(
      `loadDOMScript(url = ${url || ""})\nJSON.stringify(error)`
    );
  }
}
