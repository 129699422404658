import './LsLogo.scss';

import imgCornerBL from 'assets/images/logo/cornerBL.webp';
import imgCornerTR from 'assets/images/logo/cornerTR.webp';
import imgS from 'assets/images/logo/s.webp';
import React from 'react';
import { Fade } from 'react-awesome-reveal';

export default function LsLogo (): React.ReactNode {
  return (
    <div className={ `row justify-content-center mb-5 ` }>
      <div className="ls-logo">
        <div className="logo-bl">
          <Fade delay={ 1500 } triggerOnce>
            <img className="img-fluid" src={ imgCornerBL } alt="" />
          </Fade>
        </div>

        <div className="logo-tr">
          <Fade delay={ 500 } triggerOnce>
            <img className="img-fluid" src={ imgCornerTR } alt="" />
          </Fade>
        </div>

        <div className="logo-s">
          <Fade delay={ 2500 } triggerOnce>
            <img className="img-fluid" src={ imgS } alt="" />
          </Fade>
        </div>
      </div>
    </div>
  );
}
