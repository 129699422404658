import './CV.scss';

import clsx from 'clsx';
import Icon from 'components/Icon';
import PageSection from 'components/PageSection';
import SectionHeader from 'components/SectionHeader';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { BaseFrontMatter } from 'templates/top-index';
import { logRender } from 'utils/devLoggers';

interface CVSectionFrontMatter extends BaseFrontMatter {
  anchor?: string;
  email: string;
  fileName: string;
  header: string;
  linkedIn?: string;
  subheader?: string;
  telephone?: string;
}

interface Props {
  className?: string;
  frontmatter: CVSectionFrontMatter;
}

const CV = ({ className, frontmatter }: Props) => {
  logRender("CV");
  if (!frontmatter) return null;

  const { header, subheader, fileName } = frontmatter;

  // remove the static part of the path to leave the public path
  const resolvedCVPath = fileName ? fileName.replace("/static", "") : "";

  return (
    <PageSection className={clsx("cv-section", className)}>
      <Row className='justify-content-center'>
        <SectionHeader header={header} subheader={subheader} />
      </Row>

      <Row className='justify-content-center align-content-center m-0 p-0'>
        <a
          id='cv-anchor'
          className='pulsingButton'
          href={resolvedCVPath}
          target='_blank'
          rel='noopener noreferrer'
          title='Laura Sodaymay CV'
        >
          <Icon iconName='FileDownloadIcon' className='download-icon' />
        </a>
      </Row>
    </PageSection>
  );
};

export default CV;
