import './BackgroundMedia.scss';

import useNetworkType from 'hooks/useNetworkType';
import React from 'react';

import loadable from '@loadable/component';

import { DelayedVideoProps } from './DelayedVideo';
import Image from './Image';

const posterPath = "/static/content/assets/images/bg-video-frame-0.jpg";
const videoPath =
  "/static/content/assets/videos/Website_background_short_loop_min.mp4";

const PosterImageElement = (
  <Image
    className='bg-image'
    fileName={posterPath}
    alt='background with stars'
  />
);

const LoadableVideoComponent = loadable<DelayedVideoProps>(
  () =>
    new Promise((res) => {
      setTimeout(res, 2000);
    }).then(() => import(`./DelayedVideo`)),
  {
    fallback: PosterImageElement,
  }
);

const BackgroundMedia = () => {
  const networkType = useNetworkType();

  return (
    <div className='background-media-container'>
      {networkType === "4g" ? (
        <LoadableVideoComponent
          className='bg-video'
          poster={posterPath.replace("/static", "")}
          srcMp4={videoPath.replace("/static", "")}
        />
      ) : (
        PosterImageElement
      )}
    </div>
  );
};

export default React.memo(BackgroundMedia);
