import './AboutMe.scss';

import clsx from 'clsx';
import Image from 'components/Image';
import PageSection from 'components/PageSection';
import SectionHeader from 'components/SectionHeader';
import WeirdShape from 'components/WeirdShape';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { BaseFrontMatter } from 'templates/top-index';
import { logRender } from 'utils/devLoggers';
import md2html from 'utils/markdownToHtml';

interface AboutMeSectionFrontMatter extends BaseFrontMatter {
  anchor: string;
  content?: string;
  contentMarkdown: string;
  header: string;
  imageFileName: string;
  subheader?: string;
}

interface Props {
  className?: string;
  frontmatter: AboutMeSectionFrontMatter;
}

const AboutMe = ({ className, frontmatter }: Props) => {
  logRender("AboutMe");
  if (!frontmatter) {
    console.error(__filename, "Frontmatter is", { frontmatter });
    return null;
  }

  const {
    header: rootHeader,
    subheader: rootSubHeader,
    imageFileName,
    contentMarkdown,
  } = frontmatter;
  const contentHTML = md2html(contentMarkdown);
  // console.log(__filename, { imageFileName, contentMarkdown });

  // todo improve this, why are there 3 divs nested
  return (
    <PageSection className={clsx("row", "about-me-section", "px-4", className)}>
      <Col>
        <Row className='header'>
          <SectionHeader header={rootHeader} subheader={rootSubHeader} />
        </Row>
        <div className='row justify-content-center'>
          <div className='row weird-container'>
            <div className='weird-shape'>
              <WeirdShape />
              <div
                className='content'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: contentHTML }}
              />
            </div>
            <div className='moi-img'>
              <Image
                fileName={imageFileName}
                alt='About me image'
                className='img-fluid'
              />
            </div>
          </div>
        </div>
      </Col>
    </PageSection>
  );
};

export default AboutMe;
