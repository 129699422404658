import React from 'react';

const PrivacyInfo: React.FC = () => (
  <p>
    Ce site est protégé par reCAPTCHA et{" "}
    <a
      target='_blank'
      rel='noreferrer noopener'
      href='https://policies.google.com/privacy'
    >
      la politique de confidentialité
    </a>{" "}
    et{" "}
    <a
      target='_blank'
      rel='noreferrer noopener'
      href='https://policies.google.com/terms'
    >
      {" "}
      les conditions d&apos;utilisation
    </a>{" "}
    de Google s&apos;appliquent.
  </p>
);

export default PrivacyInfo;
