import React from 'react';
import { assign, createMachine } from 'xstate';

import { CheckCircleIcon, SpinnerIcon, TimesCircleIcon } from '../../SystemIcons';

// Available variables:
// - Machine
// - interpret
// - assign
// - send
// - sendParent
// - spawn
// - raise
// - actions
// - XState (all XState exports)

type ContactFormEvents =
  | { type: "VALID_ENTRY" }
  | { type: "INVALID_ENTRY" }
  | { type: "CLEAR" }
  | { type: "SUBMIT" }
  | { type: "ERROR"; error: string }
  | { type: "SENT" }
  | { type: "RETRY" };

type ContactFormStates =
  | {
      value: "blank";
      context: ContactFormContext & { buttonContent: undefined };
    }
  | { value: "valid"; context: ContactFormContext }
  | {
      value: "invalid";
      context: ContactFormContext & { buttonContent: undefined };
    }
  | { value: "failure"; context: ContactFormContext }
  | { value: "success"; context: ContactFormContext }
  | { value: "submitting"; context: ContactFormContext };

export type ContactFormStateNames = ContactFormStates["value"];

/** User defined values */
export interface CreateContactFormMachineProps {
  failureText: string;
  invalidText: string;
  submitText: string;
  submittingText: string;
  successText: string;
}
interface ContactFormContext extends CreateContactFormMachineProps {
  buttonContent?: React.ReactNode;
  buttonEnabled: boolean;
  buttonVariant: "primary" | "danger" | "secondary" | "dark" | "warning";
  inputsEnabled: boolean;
  showFieldValidation: boolean;
}

const validStateContext = assign<ContactFormContext, ContactFormEvents>({
  buttonContent: ({ submitText }) => submitText,
  inputsEnabled: true,
  buttonVariant: "primary",
  buttonEnabled: true,
  showFieldValidation: false,
});

const assignInValidStateContext = assign<ContactFormContext, ContactFormEvents>(
  {
    buttonContent: ({ invalidText }) => invalidText,
    inputsEnabled: true,
    buttonVariant: "secondary",
    buttonEnabled: false,
    showFieldValidation: true,
  }
);

const assignBlankStateContext = assign<ContactFormContext, ContactFormEvents>({
  buttonContent: ({ invalidText }) => invalidText,
  inputsEnabled: true,
  buttonVariant: "secondary",
  buttonEnabled: false,
  showFieldValidation: false,
});

const assignSubmittingStateContext = assign<
  ContactFormContext,
  ContactFormEvents
>({
  buttonContent: ({ submittingText }) => (
    <>
      <SpinnerIcon color='white' spin size='lg' /> {submittingText}
    </>
  ),
  inputsEnabled: false,
  buttonVariant: "primary",
  buttonEnabled: false,
  showFieldValidation: false,
});

const assignSuccessStateContext = assign<ContactFormContext, ContactFormEvents>(
  {
    buttonContent: ({ successText }) => (
      <>
        <CheckCircleIcon color='white' size='lg' /> {successText}
      </>
    ),
    inputsEnabled: true,
    buttonVariant: "primary",
    buttonEnabled: false,
    showFieldValidation: false,
  }
);

const assignFailureStateContext = assign<ContactFormContext, ContactFormEvents>(
  {
    buttonContent: ({ failureText }) => (
      <>
        <TimesCircleIcon color='yellow' size='lg' /> {failureText}
      </>
    ),
    inputsEnabled: true,
    buttonVariant: "danger",
    buttonEnabled: true,
    showFieldValidation: false,
  }
);

export default function createContactFormMachine({
  failureText = "Fail",
  successText = "Success",
  submittingText = "Submitting",
  submitText = "Submit",
  invalidText,
}: CreateContactFormMachineProps) {
  return createMachine<
    ContactFormContext,
    ContactFormEvents,
    ContactFormStates
  >({
    id: "contact-form",
    initial: "blank",
    context: {
      inputsEnabled: true,
      buttonContent: undefined,
      failureText,
      submittingText,
      successText,
      submitText,
      invalidText,
      buttonEnabled: false,
      buttonVariant: "secondary",
      showFieldValidation: false,
    },
    states: {
      blank: {
        entry: assignBlankStateContext,
        on: {
          VALID_ENTRY: "valid",
          INVALID_ENTRY: "invalid",
          CLEAR: "blank",
        },
      },
      valid: {
        entry: validStateContext,
        on: {
          INVALID_ENTRY: "invalid",
          SUBMIT: "submitting",
          CLEAR: "blank",
        },
      },
      invalid: {
        entry: assignInValidStateContext,
        on: {
          CLEAR: "blank",
          VALID_ENTRY: "valid",
        },
      },
      submitting: {
        entry: assignSubmittingStateContext,
        on: {
          SENT: "success",
          ERROR: "failure",
        },
      },
      success: {
        entry: assignSuccessStateContext,
        on: {
          CLEAR: "blank",
          VALID_ENTRY: "valid",
          INVALID_ENTRY: "invalid",
        },
      },
      failure: {
        entry: assignFailureStateContext,
        on: {
          SUBMIT: "submitting", // resubmit
          VALID_ENTRY: "valid",
          INVALID_ENTRY: "invalid",
        },
      },
    },
  });
}
