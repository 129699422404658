// deep importing is an alternative to tree shaking with more compatibility:
// https://fontawesome.com/how-to-use/javascript-api/other/tree-shaking

import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBlog } from '@fortawesome/free-solid-svg-icons/faBlog';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress';
import { faDragon } from '@fortawesome/free-solid-svg-icons/faDragon';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag';
import { faHotel } from '@fortawesome/free-solid-svg-icons/faHotel';
import { faLaptop } from '@fortawesome/free-solid-svg-icons/faLaptop';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons/faNewspaper';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { faPoll } from '@fortawesome/free-solid-svg-icons/faPoll';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons/faShoppingBasket';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';

import makeFAIcon, { LocalFAIcon } from '../utils/makeFAIcon';

// Brand Icons
export const YoutubeIcon = makeFAIcon(faYoutube);
export const TwitterIcon = makeFAIcon(faTwitter);
export const FacebookIcon = makeFAIcon(faFacebookF);
export const LinkedinIcon = makeFAIcon(faLinkedinIn);
export const InstagramIcon = makeFAIcon(faInstagram);

// General icons
export const TimesCircleIcon = makeFAIcon(faTimesCircle);
export const CheckCircleIcon = makeFAIcon(faCheckCircle);
// export const PhoneIcon = makeFAIcon(faPhone);
export const EnvelopIcon = makeFAIcon(faEnvelope);
// export const PlusIcon = makeFAIcon(faPlus);
export const BarsIcon = makeFAIcon(faBars);
// export const GithubIcon = makeFAIcon(faGithubAlt);
// export const MediumIcon = makeFAIcon(faMediumM);
// export const CloseIcon = makeFAIcon(faTimes);
export const LanguageIcon = makeFAIcon(faGlobe);
export const ArrowUpIcon = makeFAIcon(faArrowUp);
export const ChevronLeftIcon = makeFAIcon(faChevronLeft);
export const ChevronRightIcon = makeFAIcon(faChevronRight);
export const ChevronDownIcon = makeFAIcon(faChevronDown);
export const BuildingIcon = makeFAIcon(faBuilding);
export const UsersIcon = makeFAIcon(faUsers);
export const LocationIcon = makeFAIcon(faGlobeAmericas);
export const QuoteLeftIcon = makeFAIcon(faQuoteLeft);
export const QuoteRightIcon = makeFAIcon(faQuoteRight);
export const QuestionCircleIcon = makeFAIcon(faQuestionCircle);
export const FileDownloadIcon = makeFAIcon(faFileDownload);
export const BlogIcon = makeFAIcon(faBlog);
export const PollIcon = makeFAIcon(faPoll);
export const HashtagIcon = makeFAIcon(faHashtag);
export const CalendarAltIcon = makeFAIcon(faCalendarAlt);
export const CompressIcon = makeFAIcon(faCompress);
export const DragonIcon = makeFAIcon(faDragon);
export const GraduationCapIcon = makeFAIcon(faGraduationCap);
// export const TruckMonsterIcon = makeFAIcon(faTruckMonster);
export const HandsHelpingIcon = makeFAIcon(faHandsHelping);
export const HotelIcon = makeFAIcon(faHotel);
export const BullhornIcon = makeFAIcon(faBullhorn);
export const NewspaperIcon = makeFAIcon(faNewspaper);
export const CarIcon = makeFAIcon(faCar);
export const ShoppingBasketIcon = makeFAIcon(faShoppingBasket);
export const PlayCircleIcon = makeFAIcon(faPlayCircle);
export const SpinnerIcon = makeFAIcon(faSpinner);
export const ShoppingCartIcon = makeFAIcon(faShoppingCart);
export const LaptopIcon = makeFAIcon(faLaptop);
export const LockIcon = makeFAIcon(faLock);

export interface AllImportInterface {
  [key: string]: LocalFAIcon | undefined;
}
