/* eslint-disable import/prefer-default-export */
export const ALLOWED_ORIGINS = [
  "https://www.laurasodaymay.com",
  "laura-sodaymay.netlify.app",
  "http://localhost:8000",
  "http://localhost:8888",
  "localhost:8888",
];

export const BREAK_TAG = "<br/>";
