import { ImageEdge, ImageQueryData } from '../declarations/interfaces';
import { ImageEdgesMap } from '../declarations/types';

interface AddPathVariantsProps {
  edge: ImageEdge;
  imageEdgesMap: ImageEdgesMap;
}
// todo add tests
// todo delete comments

function addPathVariants({ imageEdgesMap, edge }: AddPathVariantsProps) {
  if (!edge || !edge.node)
    return console.warn("addPathVariants", "image edge or node is falsy", {
      edge,
    });

  const relativePath = edge?.node?.relativePath?.replace(/.*\/images\//, "");

  if (!relativePath?.trim())
    return console.error(
      __filename,
      `Could not add path variants, relative path could not be found`,
      { edge }
    );

  const pathSegments = relativePath.split("/");

  // set initial path as blank
  let localPath = "";

  // todo do this as a reduce
  // generate relative path variants for current base path, starting from the right
  for (
    let pathVariantNo = pathSegments.length - 1;
    pathVariantNo >= 0;
    pathVariantNo--
  ) {
    localPath = `${pathSegments[pathVariantNo]}${localPath && "/" + localPath}`;
    imageEdgesMap.set(localPath, edge);
  }
}
function populateImagesMap(data: ImageQueryData, imageEdgesMap: ImageEdgesMap) {
  data.allFile.edges.forEach((edge) =>
    addPathVariants({ imageEdgesMap, edge })
  );
}

export default populateImagesMap;
