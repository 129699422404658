import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { HTMLContactFormElement } from '../declarations';
import handleSubmit from '../utils/handleSubmit';

interface Props {
  captchaElementRef: React.RefObject<ReCAPTCHA>;
  formRef: React.RefObject<HTMLContactFormElement>;

  sendEvent(event: any, payload?: any): unknown;
}

const ReCAPTCHAConfigured: React.FC<Props> = ({
  captchaElementRef,
  formRef,
  sendEvent,
}) => (
  <ReCAPTCHA
    ref={captchaElementRef}
    // public key: https://cloud.google.com/recaptcha-enterprise/docs/create-key
    sitekey='6LfUxIQaAAAAABeQDxCv979ov1pZrWV2vrst8luU'
    onChange={(recaptchaResponse) =>
      recaptchaResponse
        ? handleSubmit({
            recaptchaResponse,
            captchaElementRef,
            formRef,
            sendEvent,
          })
        : console.error(
            `recaptcha response is falsy, ie ${typeof recaptchaResponse}`
          )}
    size='invisible'
    theme='dark'
    onErrored={console.error}
    badge='inline'
    onExpired={() => captchaElementRef.current?.reset()}
  />
);

export default ReCAPTCHAConfigured;
