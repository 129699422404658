import { ContactFormSubmitProps } from '../declarations';

const contactFormDetailsValid = (
  contactFormSubmitProps: ContactFormSubmitProps
) => {
  const {
    senderName: name,
    email,
    message,
    recaptchaResponse,
  } = contactFormSubmitProps;
  const allFieldsDefined = name && email && message && recaptchaResponse;
  if (allFieldsDefined) return true;

  return console.error(
    `Could not submit, some required properties not defined: ${JSON.stringify(
      contactFormSubmitProps
    )}`,
    contactFormSubmitProps
  );
};

export default contactFormDetailsValid;
