import { GenericObject } from 'declarations/general-interfaces';
import React from 'react';
import { SectionMarkdownNode } from 'templates/top-index';
import { logRender } from 'utils/devLoggers';
import fileNameToSectionName from 'utils/fileNameToSectionName';
import * as _Sections from 'views/Sections';

const Sections = _Sections as GenericObject<React.FC<any>>;

export interface PageBodyProps {
  sectionsNodes: SectionMarkdownNode[];
}

export default function PageBody({ sectionsNodes }: PageBodyProps) {
  logRender("PageBody");
  return (
    <div style={{ paddingBottom: "54px" }}>
      {sectionsNodes.map(({ frontmatter, fields: { fileName } }, i) => {
        if (!frontmatter) {
          console.error("PageBody", "Bad Frontmatter", {
            fileName,
            frontmatter,
          });
          return null;
        }
        if (frontmatter.hidden) {
          console.warn(
            "PageBody",
            `The "${fileName}" section is hidden as specified in the Markdown front matter`
          );
          return null;
        }

        const sectionComponentName = fileNameToSectionName(fileName);
        const SectionComponent = React.memo(
          Sections[sectionComponentName] as React.FC<any>
        );

        return (
          SectionComponent && (
            <div id={frontmatter.anchor} key={sectionComponentName}>
              <SectionComponent
                className={i % 2 === 1 ? "bg-gray" : ""}
                frontmatter={frontmatter}
              />
            </div>
          )
        );
      })}
    </div>
  );
}
