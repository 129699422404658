import * as _Icons from 'components/SystemIcons';
import React from 'react';
import { LocalFAIcon, LocalFAIconProps } from 'utils/makeFAIcon';

const Icons = _Icons as _Icons.AllImportInterface;

export interface Props extends LocalFAIconProps {
  iconName: string;
}

const Icon = ({ iconName, ...restProps }: Props) => {
  let IconComponent: LocalFAIcon | undefined = Icons[iconName];
  if (!IconComponent) {
    IconComponent = Icons.QuestionCircleIcon;
    if (!IconComponent) throw Error(`Default icon not defined`);
  }

  return <IconComponent {...restProps} />;
};

export default Icon;
