import './PortfolioItem.scss';

import clsx from 'clsx';
import Image from 'components/Image';
import PortfolioContent from 'components/PortfolioContent';
import * as Icons from 'components/SystemIcons';
import React from 'react';
import { Col } from 'react-bootstrap';
import { scroller } from 'react-scroll';
import { logRender } from 'utils/devLoggers';
import isCms from 'utils/isCMS';
import sleep from 'utils/sleep';

const isCMS = isCms();
const CompressIcon = Icons.ChevronDownIcon;

interface Props {
  expanded: boolean;
  header: string;
  imageAlt?: string;
  imageFileName: string;
  itemKey: string;
  setExpandedKey: (newKey: string) => void;
  subheader?: string;
}

const PortfolioItem = ({
  itemKey,
  setExpandedKey,
  expanded,
  imageFileName,
  header,
  subheader,
  imageAlt,
  ...restProps
}: Props) => {
  logRender("PortfolioItem");
  const toggleExpanded = () => {
    /* event: React.MouseEvent<HTMLDivElement, MouseEvent> */
    // current item is open, close it else open it
    if (expanded) {
      setExpandedKey("");
    } else {
      setExpandedKey(itemKey);
    }

    // scroll after delay to account for any position changes in the grid
    sleep(600).then(() => {
      // handle scrolling differently if it is a cms preview
      if (isCMS) {
        const cmsPreviewIFrame = document.getElementById(
          "preview-pane"
        ) as HTMLIFrameElement | null;

        // todo scroll to header instead
        // scroll to the top of the iFrame window
        cmsPreviewIFrame?.contentWindow?.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } else {
        scroller.scrollTo(header, {
          duration: 500,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -70,
        });
      }
    });
  };

  return (
    <Col
      className={clsx("portfolio-item col-12", {
        "col-sm-6 col-lg-4 ": !expanded,
        "item-expanded": expanded,
      })}
    >
      <div
        id={header}
        role='button'
        tabIndex={-1}
        className='portfolio-link'
        data-toggle='modal'
        // onClick={handleShowDetail}
        onClick={toggleExpanded}
        onKeyUp={() => console.warn(__filename, `key up handler tbc`)}
      >
        <Image
          className='img-fluid img-responsive portfolio-img'
          fileName={imageFileName}
          alt={imageAlt || header || subheader || ""}
        />
        <div className='portfolio-hover'>
          <div className='portfolio-hover-content row no-gutters'>
            {expanded && (
              <CompressIcon className='compress-icon col-1' size='1x' />
            )}
            <Col>
              <h3>{header}</h3>
            </Col>
            {expanded && <div className='col-1' />}
          </div>
        </div>
      </div>

      {expanded && (
        <div className='content'>
          <PortfolioContent subheader={subheader} {...restProps} />
        </div>
      )}
    </Col>
  );
};

export default PortfolioItem;
