import './PortfolioContentVideo.scss';

import Carousel from 'components/Carousel/Carousel';
import { graphql } from 'gatsby';
import React from 'react';
import { logRender } from 'utils/devLoggers';

import YoutubeSlide, { YoutubeSlideProps } from './sub-components/YoutubeSlide';
import youtubeVideoUrlToId from './utils/convertYoutubeUrlToId';
import createProcessedYoutubeUrl from './utils/createProcessedYoutubeUrl';
import createThumbRenderer from './utils/createThumbRenderer';

// todo find a smaller solution than react player

// todo refactor this

export const fragment = graphql`
  fragment PortfolioContentVideo on MarkdownRemarkFrontmatterPortfolios {
    videos {
      videoTitle
      videoDescription
      youtubeURL
    }
  }
`;
export interface VideoFrontMatter {
  videoDescription?: string;
  videoTitle: string;
  youtubeURL: string;
}

interface Props {
  videos: VideoFrontMatter[];
}

const PortfolioContentVideo = ({ videos }: Props) => {
  logRender("PortfolioContentVideo");
  // console.log(__filename, { videos });

  // todo create array of youtube ids at the beginning to be used for main video and also getting thumb nails, see example here http://react-responsive-carousel.js.org/storybook/?path=/story/02-advanced--youtube-autoplay-with-custom-thumbs

  // todo dont navigate away if video is playing
  // todo subheader and text should use local css class, not parent css

  const processedData = videos.map(
    ({ videoTitle, youtubeURL, videoDescription }) => {
      const videoid = youtubeVideoUrlToId(youtubeURL);
      const url = createProcessedYoutubeUrl(videoid);

      return {
        alt: videoDescription,
        descriptionMarkdown: videoDescription,
        title: videoTitle,
        url,
        videoid
      } as YoutubeSlideProps;
    }
  );

  /*
  const videosJSX = videos.map((video) => {
    if (!video.youtubeURL) {
      console.error(__filename, "there is a video with url undefined", {
        video
      });
      return null;
    }

    const youtubeURL = processYoutubeUrl(video.youtubeURL);

    // console.log(__filename, 'youtube link after edits', { youtubeURL, videoId, originalURL: video.youtubeURL, sArr });

    return <YoutubeSlide key={youtubeURL} url={youtubeURL} video={video} />;
  });
  */

  // ? what is this doing? I think this was to pass the React props to HTML so they could be read
  /*
  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );
  */

  // todo make sure view of video on mobile looks good

  return (
    videos && (
      <Carousel
        className="video-carousel-container"
        renderThumbs={createThumbRenderer(processedData)}
        autoPlay={false}
      >
        {processedData.map((props) => (
          <YoutubeSlide key={props.url} {...props} />
        ))}
      </Carousel>
    )
  );
};

export default PortfolioContentVideo;
