import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ServiceItem from "components/ServiceItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

export const fragment = graphql`
  fragment ServicesSection on Frontmatter {
    services {
      content
      header
      iconName
      imageFileName
    }
  }
`;

const Services = ({ className, frontmatter }) => {
  if (!frontmatter) {
    console.error(__filename, "Frontmatter is", { frontmatter });
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    services
  } = frontmatter;

  return (
    <PageSection className={className}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="text-center mx-1">
        {services.map((service) => (
          <Col md={6} key={service.header}>
            <ServiceItem {...service} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Services.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object
};

Services.defaultProps = {
  className: null,
  frontmatter: null
};

export default Services;
