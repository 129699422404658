import axios from 'axios';

import { ContactFormSubmitProps } from '../declarations';

const sendFormData = async (
  contactFormSubmitProps: ContactFormSubmitProps,
  sendFormEndPoint: string
) => {
  try {
    await axios.post(sendFormEndPoint, contactFormSubmitProps, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  } catch (error) {
    console.error(__filename, "error sending form data", { error });
    throw Error("Error sending form data");
  }
};

export default sendFormData;
