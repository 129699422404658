/* eslint-disable @typescript-eslint/indent */
import './ContactForm.scss';

import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from 'react-google-recaptcha';

import { useMachine } from '@xstate/react';

import { logRender } from '../../utils/devLoggers';
import { ContactFormProps, HTMLContactFormElement } from './declarations';
import createContactFormMachine from './state-machines/contactFormMachine';
import { EmailField, MessageField, NameField } from './sub-components/FormFields';
import PrivacyInfo from './sub-components/PrivacyInfo';
import ReCAPTCHAConfigured from './sub-components/ReCAPTCHAConfigured';
import SubmitButton from './sub-components/SubmitButton';

export default function ContactForm({
  nameFieldTitle = "Name",
  nameFieldPlaceholder = "Enter name",
  emailFieldTitle = "Email",
  emailFieldPlaceholder = "Enter email",
  messageFieldTitle = "Message",
  messageFieldPlaceholder = "Enter message",
  submitButtonText = "Enter message",
}: ContactFormProps): React.ReactElement {
  logRender("ContactForm");
  const formRef = React.useRef<HTMLContactFormElement>(null);
  const captchaElementRef = React.useRef<ReCAPTCHA>(null);

  const [currentState, sendEvent] = useMachine(
    createContactFormMachine({
      failureText: "Désolé il y avait une erreur 😢... réessayer?",
      submittingText: "Envoi du message...",
      successText: "Envoyé 👍",
      submitText: submitButtonText,
      invalidText:
        "Veuillez ajouter les détails requis pour envoyer un message s'il vous plait",
    })
  );

  const handleFieldChange = () => {
    // send an event based on if the change resulted in valid or invalid form data
    if (formRef.current)
      sendEvent(
        formRef.current.checkValidity() ? "VALID_ENTRY" : "INVALID_ENTRY"
      );
  };

  return (
    <Form
      className='contact-form'
      name='contact'
      method='post'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
      validated={currentState.context.showFieldValidation}
      ref={formRef}
    >
      <input type='hidden' name='form-name' value='contact' />
      <Row>
        <Col lg={6}>
          <NameField
            placeholder={nameFieldPlaceholder}
            title={nameFieldTitle}
            disabled={!currentState.context.inputsEnabled}
            handleChange={handleFieldChange}
          />
        </Col>
        <Col lg={6}>
          <EmailField
            placeholder={emailFieldPlaceholder}
            title={emailFieldTitle}
            disabled={!currentState.context.inputsEnabled}
            handleChange={handleFieldChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MessageField
            placeholder={messageFieldPlaceholder}
            title={messageFieldTitle}
            disabled={!currentState.context.inputsEnabled}
            handleChange={handleFieldChange}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <SubmitButton
              variant={currentState.context.buttonVariant}
              content={currentState.context.buttonContent}
              disabled={!currentState.context.buttonEnabled}
              onClick={() => captchaElementRef.current?.execute()}
            />
          </Row>
          <Row>
            <PrivacyInfo />
            <ReCAPTCHAConfigured
              captchaElementRef={captchaElementRef}
              formRef={formRef}
              sendEvent={sendEvent}
            />
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
