
import React from 'react';

import useWindowOnScroll from './useWindowOnScroll';

export default function useShowNavbarFooterOnScroll (): boolean {
  const [ show, setShow ] = React.useState( false );

  const handleWindowScroll = React.useCallback( () => {
    const scrollDistanceFromTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    // console.log("Navbar", "scrollTop:", scrollTop);
    setShow( scrollDistanceFromTop > 100 );
    // setShowScrollUpFAB(scrollTop > 400);
  }, [] );
  useWindowOnScroll( handleWindowScroll );

  return show;
};
