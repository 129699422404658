export default function convertYoutubeUrlToId(rawUrl: string) {
  // make sure URL is for embed
  // console.log(__filename, 'original youtube link:', e.youtubeURL);
  const processedURL = rawUrl.replace(/watch\?v=/, "embed/");

  /*
		todo accomodate other types of youtube links e.g.
		- https://youtu.be/Qm5oFTBS7yA
		- https://youtu.be/OapxMsZHNkw
		*/

  // get video id
  const sArr = processedURL.split("/");
  const videoId = sArr[sArr.indexOf("embed") + 1];

  return videoId;
}
