import React from 'react';
import Button from 'react-bootstrap/Button';

interface Props {
  content?: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
  variant: string;
}

const SubmitButton: React.FC<Props> = ({
  variant,
  content,
  disabled,
  onClick,
}) => {
  if (!content)
    return (
      <p>
        Veuillez ajouter les détails requis pour envoyer un message s&apos;il
        vous plait
      </p>
    );

  return (
    <Button
      variant={variant}
      className='btn'
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </Button>
  );
};

export default SubmitButton;
