import '@fontsource/bad-script';
import '@fontsource/courgette';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/raleway';

import BackgroundMedia from 'components/BackgroundMedia';
import SEO from 'components/SEO';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import loadGoogleTracking from 'utils/loadGoogleTracking';

const handleError = (error: any, info: any) => {
  console.error(__filename, "ERROR OCCURED", { error, info });
};

interface Props {
  children?: React.ReactChildren | React.ReactNode;
  description?: string;
  isCms?: boolean;
  keywords?: string[];
  langKey?: string;
}

const DefaultLayout = ({
  langKey = "fr",
  keywords,
  description,
  children,
  isCms = false,
}: Props) => {
  React.useEffect(() => {
    void loadGoogleTracking();
  }, []);
  // ! Helmet doesn't seem to work in CMS preview so this needs to be in the body when its a cms

  // ? does SEO really need to be disabled for CMS?
  return (
    <div className='default-layout' style={{ position: "relative" }}>
      <SEO
        lang={langKey}
        title='LS'
        keywords={keywords}
        description={description}
        isCms={isCms}
      />

      <Helmet>
        <meta charSet='utf-8' />
      </Helmet>

      <div className='default-layout'>
        <BackgroundMedia />
        {children}
      </div>
    </div>
  );
};

const AppErrorBoundary: React.FC = ({ children }) => (
  <ErrorBoundary
    FallbackComponent={({ error }) => (
      <div>
        An error OCCURRED: <p>{JSON.stringify(error)}</p>
      </div>
    )}
    onError={handleError}
  >
    {children}
  </ErrorBoundary>
);

// todo apply react memo to more components down the tree
export default React.memo(DefaultLayout);
