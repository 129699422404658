import './Footer.scss';

import clsx from 'clsx';
import Icon from 'components/Icon';
import * as SocialIcons from 'components/SocialIcons';
import { graphql } from 'gatsby';
import useShowNavbarFooterOnScroll from 'hooks/useShowNavbarFooterOnScroll';
import useSmoothScrollTo from 'hooks/useSmoothScrollTo';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { logRender } from 'utils/devLoggers';

const CURRENT_YEAR = new Date().getFullYear();

export const fragment = graphql`
  fragment FooterSection on Frontmatter {
    privacyHref
    privacyText
    termsHref
    termsText
    copyright
    social {
      facebook
      github
      linkedin
      medium
      twitter
      email
    }
  }
`;

interface FooterSocialIconOptions {
  email?: string;
  facebook?: string;
  github?: string;
  linkedin?: string;
  medium?: string;
  twitter?: string;
}

export interface FooterFrontmatter {
  copyright: string;
  privacyHref?: string;
  privacyText?: string;
  social: FooterSocialIconOptions;
  termsHref?: string;
  termsText?: string;
}

export interface FooterProps {
  frontmatter: FooterFrontmatter;
  isCms?: boolean;
}

function Footer({ frontmatter, isCms }: FooterProps) {
  logRender("Footer");
  const showFooter = useShowNavbarFooterOnScroll();

  const handleScrollToTop = useSmoothScrollTo(0);

  if (!frontmatter) {
    console.error(__filename, "Frontmatter is", { frontmatter });
    return null;
  }

  const {
    copyright,
    social: { facebook, github, email, linkedin, twitter },
  } = frontmatter;

  const socialIcons = [] as [string, React.ReactElement][];
  if (twitter)
    socialIcons.push([
      "Twitter footer icon",
      <SocialIcons.Twitter key={twitter} userName={twitter} />,
    ]);
  if (facebook)
    socialIcons.push([
      "Facebook footer icon",
      <SocialIcons.Facebook key={facebook} userName={facebook} />,
    ]);
  if (linkedin)
    socialIcons.push([
      "LinkedIn footer icon",
      <SocialIcons.Linkedin key={linkedin} userName={linkedin} />,
    ]);
  if (email)
    socialIcons.push([
      "Email footer icon",
      <SocialIcons.Email key={email} email={email} />,
    ]);
  if (github)
    socialIcons.push([
      "Github footer icon",
      <SocialIcons.Github key={github} userName={github} />,
    ]);
  // if (medium) socialIcons.push(<SocialIcons.Medium userName={medium} />);

  // todo implement keyup handler, e.g. ctrl+up scrolls up

  return (
    <footer
      className={clsx("footer", "fixed-bottom", {
        "footer-shrink": showFooter || isCms,
      })}
    >
      <div className='line bg-gradient-primary' />
      <Row noGutters className='px-3 align-items-center text-left'>
        <Col className='flex-grow-1 m-0 p-0 text-left footer-text'>
          {`${copyright} ${CURRENT_YEAR}`}
        </Col>
        <Col xs='auto' className='social-icon-container'>
          <Row noGutters>
            {socialIcons.map(([key, SocialIcon]) => (
              <div key={key} className='social-icon'>
                {SocialIcon}
              </div>
            ))}
          </Row>
        </Col>
      </Row>
      <div
        role='button'
        tabIndex={-1}
        className='circle-button'
        onClick={handleScrollToTop}
        aria-label='Scroll Up'
        onKeyUp={() => console.error(`keyup tbc`)}
      >
        <Icon iconName='ArrowUpIcon' inverse id='scroll-up-fab' />
      </div>
    </footer>
  );
}

export default React.memo(Footer);
