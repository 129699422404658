import React from "react";

import PageSection from "components/PageSection";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <PageSection>
    <Row className='justify-content-center'>
      <Col>
        <h1>Sorry this page doesn&apos;t exist</h1>
        <p>Click the link below to return to the real world</p>

        <Link className='nav-link cursor-pointer' activeClass='active' to='/'>
          Take me home
        </Link>
      </Col>
    </Row>
  </PageSection>
);

export default NotFoundPage;
