import './PageSection.scss';

import clsx from 'clsx';
import React from 'react';
import { Slide } from 'react-awesome-reveal';
import { Container } from 'react-bootstrap';
import { logRender } from 'utils/devLoggers';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

// todo implement loadable here, where header should be shown at all times to allow jump from toolbar
const PageSection = ({ children, className, ...restProps }: Props) => {
  logRender("PageSection", { children });

  return (
    <Slide direction="up" fraction={0.05} triggerOnce>
      <section className={clsx("page-section", className)} {...restProps}>
        <Container fluid>{children}</Container>
      </section>
    </Slide>
  );
};

export default React.memo(PageSection);
