import React, { Children } from "react";
import PropTypes from "prop-types";
import { logRender } from "utils/devLoggers";
// import DOMPurify from "dompurify";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { graphql } from "gatsby";
// import { MDXRenderer } from 'gatsby-plugin-mdx';
// import ReactMarkdown from 'react-markdown';
// import gfm from 'remark-gfm';
// import xss from 'xss';

import PortfolioContentVideo from "components/PortfolioContentVideo/PortfolioContentVideo";
import PortfolioContentArticles from "components/PortfolioContentArticles";
import PortfolioContentGallery from "components/PortfolioContentGallery";

import md2html from "utils/markdownToHtml";
import "./PortfolioContent.scss";

export const fragment = graphql`
  fragment PortfolioContent on MarkdownRemarkFrontmatterPortfolios {
    ...PortfolioContentVideo
    ...PortfolioContentArticles
    ...PortfolioContentGallery
  }
`;

// Community Management
const PortfolioContent = ({
  subheader,
  contentMarkdown,
  videos,
  articles,
  galleryImages
}) => {
  logRender("PortfolioContent");
  // console.log(__filename, { galleryImages });

  // const contentJSX = contentMarkdown && <div dangerouslySetInnerHTML={{ __html: md2html(contentMarkdown) }} />;

  // const contentJSX = contentMarkdown && <ReactMarkdown plugins={[gfm]}>{contentMarkdown}</ReactMarkdown>;

  return (
    <Row className="portfolio-content-container">
      <Col>
        {subheader && <h5 className="subheader">{subheader}</h5>}
        {contentMarkdown && (
          <div dangerouslySetInnerHTML={{ __html: md2html(contentMarkdown) }} />
        )}

        {videos && <PortfolioContentVideo videos={videos} />}
        {articles && <PortfolioContentArticles articles={articles} />}
        {galleryImages && (
          <PortfolioContentGallery galleryImages={galleryImages} />
        )}
      </Col>
    </Row>
  );
};

PortfolioContent.propTypes = {
  contentMarkdown: PropTypes.string,
  videos: PropTypes.array,
  subheader: PropTypes.string,
  articles: PropTypes.array,
  galleryImages: PropTypes.array
};

PortfolioContent.defaultProps = {
  contentMarkdown: null,
  videos: null,
  subheader: "",
  articles: null,
  galleryImages: null
};

export default PortfolioContent;
