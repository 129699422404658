/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */
import loadDOMScript from "utils/loadDOMScript";
import sleep from "./sleep";

// ! needs to be a function in this format, the arguments array for arrow functions is different apparently
function gtag() {
  if (typeof window === "undefined") return;

  window?.dataLayer?.push(arguments);
}

function loadGtagConfig() {
  if (typeof window === "undefined")
    return console.error(__filename, "window not defined, cant load gtag");

  window.dataLayer = window.dataLayer || [];
  gtag("js", new Date());
  gtag("config", "G-WBC02BEB2Z");
}

export default async function loadGoogleTracking() {
  await sleep(10000); // load this after everything else // ? is delay too long?
  await loadDOMScript(
    "https://www.googletagmanager.com/gtag/js?id=G-WBC02BEB2Z"
  ); // todo move config code to env file
  loadGtagConfig();
}
