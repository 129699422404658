import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import TimelineItem from "components/TimelineItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import replaceNewLinesWithBreakTags from "utils/replaceNewLinesWithBreakTags";

import "./About.scss";

const About = ({ className, frontmatter }) => {
  if (!frontmatter) {
    console.error(__filename, "Frontmatter is", { frontmatter });
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    timeline,
  } = frontmatter;

  return (
    <PageSection className={className}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <Col lg={12} className='mx-1'>
          <ul className='timeline'>
            {timeline.map(
              ({ header, position, imageContent, ...restProps }, ind) => (
                <TimelineItem
                  invert={ind % 2 === 1}
                  key={header + position}
                  header={header}
                  position={position}
                  {...restProps}
                  imageContent={
                    imageContent && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<h4>${replaceNewLinesWithBreakTags(
                            imageContent
                          )}</h4>`,
                        }}
                      />
                    )
                  }
                />
              )
            )}
          </ul>
        </Col>
      </Row>
    </PageSection>
  );
};

About.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

About.defaultProps = {
  className: null,
  frontmatter: null,
};

export default About;
