import './SectionHeader.scss';

import clsx from 'clsx';
import React from 'react';
import { Col } from 'react-bootstrap';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  header: string;
  subheader?: string;
}

const SectionHeader = ({
  header,
  subheader,
  className = "",
  ...restProps
}: Props) => {
  return (
    <Col
      lg={12}
      className={clsx("section-header", "text-center", className)}
      {...restProps}
    >
      <h2 className="section-heading text-uppercaseX">{header}</h2>
    </Col>
  );
};

export default SectionHeader;
