import './Contact.scss';

import HTMLContactFormElement from 'components/ContactForm';
import Icon from 'components/Icon';
import PageSection from 'components/PageSection';
import SectionHeader from 'components/SectionHeader';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { logRender } from 'utils/devLoggers';

export const fragment = graphql`
  fragment ContactSection on Frontmatter {
    email
    linkedIn
    telephone
    formSuggestionText
    nameFieldTitle
    nameFieldPlaceholder
    emailFieldTitle
    emailFieldPlaceholder
    messageFieldTitle
    messageFieldPlaceholder
    submitButtonText
  }
`;

interface ContactSectionFrontmatter {
  anchor: string;
  email: string;
  emailFieldPlaceholder: string;
  emailFieldTitle: string;
  formSuggestionText: string;
  header: string;
  linkedIn: string;
  messageFieldPlaceholder: string;
  messageFieldTitle: string;
  nameFieldPlaceholder: string;
  nameFieldTitle: string;
  subheader: string;
  submitButtonText: string;
  telephone: string;
}

interface Props {
  className?: string;
  frontmatter: ContactSectionFrontmatter;
}

const Contact = ({
  className,
  frontmatter,
}: Props): React.ReactElement | null => {
  logRender("Contact");
  if (!frontmatter) return null; // ? should this throw?

  const {
    header,
    subheader,
    email,
    linkedIn,
    nameFieldTitle,
    nameFieldPlaceholder,
    emailFieldTitle,
    emailFieldPlaceholder,
    messageFieldTitle,
    messageFieldPlaceholder,
    submitButtonText,
  } = frontmatter;

  return (
    <PageSection className={`contact-section ${className || ""}`}>
      <Row className='justify-content-center'>
        <SectionHeader header={header} subheader={subheader} />
      </Row>

      <Row className='mb-5 p-0'>
        <Col className='mx-auto text-center'>
          <a
            className='d-block'
            href={`${linkedIn}`}
            target='_blank'
            rel='noopener noreferrer'
            aria-label='LinkedIn profile link'
          >
            <Icon iconName='LinkedinIcon' className='m-3 contact-icon' />
          </a>
        </Col>

        <Col className='mx-auto  text-center'>
          <a
            className='d-block'
            href={`mailto:${email}`}
            target='_blank'
            rel='noopener noreferrer'
            aria-label='LinkedIn profile link'
          >
            <Icon
              iconName='EnvelopIcon'
              size='5x'
              className='m-3 contact-icon'
            />
          </a>
        </Col>
      </Row>

      <Row className='justify-content-center my-1'>
        <HTMLContactFormElement
          nameFieldTitle={nameFieldTitle}
          nameFieldPlaceholder={nameFieldPlaceholder}
          emailFieldTitle={emailFieldTitle}
          emailFieldPlaceholder={emailFieldPlaceholder}
          messageFieldTitle={messageFieldTitle}
          messageFieldPlaceholder={messageFieldPlaceholder}
          submitButtonText={submitButtonText}
        />
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
