const upperFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Valid Section
 * 1-Services => Services
 * 1-services => Services
 * 1- services => Services
 * Services => Services
 * services => Services
 * 1-Services.ja -> Services
 */
export default function fileNameToSectionName(fileName: string) {
  if (fileName == null || fileName === "" || typeof fileName !== "string") {
    console.warn(
      __filename,
      "fileNameToSectionName filename is invalid, returning null",
      { fileName }
    );
    return "";
  }

  const sectionName = upperFirstLetter(
    fileName
      // remove any folder names
      .replace(/^.*\//g, "")
      // remove 1-
      .replace(/\d+-/, "")
      // remove .xx (language key) ie anything after a dot
      .replace(/\..*$/i, "")
      // remove space
      .trim()
  );

  return sectionName;
}
