import CircleIcon from 'components/CircleIcon';
import React from 'react';

interface Props {
  userName: string;
}

export default function Medium({ userName }: Props) {
  return (
    <CircleIcon
      href={`https://medium.com/@${userName}`}
      iconName="MediumIcon"
    />
  );
}
