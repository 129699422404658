import CircleIcon from 'components/CircleIcon';
import React from 'react';

interface Props {
  email: string;
}

export default function Email({ email }: Props) {
  return <CircleIcon href={`mailto:${email}`} iconName="EnvelopIcon" />;
}
