import CircleIcon from 'components/CircleIcon';
import React from 'react';

interface Props {
  userName: string;
}

export default function Linkedin({ userName }: Props) {
  // see if user put in a link or a profile id
  const link = /^https?:\/\/linkedin\.com\/in\//.test(userName)
    ? userName
    : `https://linkedin.com/in/${userName}`;
  // console.log(__filename, { inputUserName: userName, linkResult: link });
  return <CircleIcon href={link} iconName="LinkedinIcon" />;
}
