import marked from 'marked';
import xss from 'xss';

import { logFunctionCall } from './devLoggers';

const markedRendererOverrides = {
  link(href: string, title: string, text: string) {
    return `\n<a target="_blank" tooltip="${href}" href="${href}">${text}</a>`;
  },
  image(href: string, title: string, text: string) {
    return `\n<img class="img-fluid" alt="${text}" src="${href}"/>`;
  },
  table(header: string, body: string) {
    return `
      <div class="table-wrapper">
        <table>
            <thead>
              ${header}
            </thead>
            <tbody>
              ${body}
            </tbody>
        </table>
      </div>`;
  },
};

export default function md2html(markdown: string): string {
  logFunctionCall("md2html");
  if (!markdown) return "";

  if (typeof markdown !== "string") {
    console.error("md2html, markdown is not a string", { markdown });
    throw Error("Markdown should be a string");
  }

  marked.use({ renderer: markedRendererOverrides });

  // ALLOWS LINE BREAKS WITH RETURN BUTTON
  marked.setOptions({
    gfm: true,
    breaks: true,
  });

  const renderedHTML = marked(markdown);
  const sanitizedHTML = xss(renderedHTML);

  return sanitizedHTML;
}
