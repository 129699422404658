import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ResponsiveEmbed from 'react-responsive-embed';
import { logRender } from 'utils/devLoggers';
import md2html from 'utils/markdownToHtml';

export interface YoutubeSlideProps {
  url: string;
  isSelected?: string;
  videoid: string;
  alt: string;
  title: string;
  descriptionMarkdown: string;
}

const YoutubeSlide = ({
  url,
  isSelected,
  title,
  alt,
  descriptionMarkdown,
  ...restProps
}: YoutubeSlideProps) => {
  logRender("YoutubeSlide");
  // const videoThumbPath = getVideoThumb(videoid);
  return (
    <Row className="h-100 ">
      <Col
        className="col-12 justify-items-center carousel-item-container"
        key={url}
      >
        {title && <h5 className="subheader">{title}</h5>}
        <div
          className="flex-grow-1"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: md2html(descriptionMarkdown)
          }}
        />

        <div className="video-container p-0" {...restProps}>
          <ResponsiveEmbed
            src={url}
            allow="autoplay; encrypted-media; fullscreen;"
            frameBorder="0"
            allowFullScreen
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            alt={alt}
            className="m-0"
          />
        </div>
      </Col>
    </Row>
  );
};

export default YoutubeSlide;
