import { RenderThumbs } from 'components/Carousel/declarations/types';
import React from 'react';
import { logRender } from 'utils/devLoggers';

import VideoThumb from '../sub-components/VideoThumb';
import { YoutubeSlideProps } from '../sub-components/YoutubeSlide';

const createThumbRenderer = (data: YoutubeSlideProps[]) => {
  logRender("PortfolioContentVideo: renderThumbsJSX");

  const renderThumbs: RenderThumbs = (children) =>
    children &&
    children.map((_, i) => (
      <VideoThumb
        key={data[i].videoid}
        description={data[i].title}
        videoid={data[i].videoid}
      />
    ));

  return renderThumbs;
};

export default createThumbRenderer;
