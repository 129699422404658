import React from "react";
import PropTypes from "prop-types";

import Nav from "react-bootstrap/Nav";
import { Link } from "react-scroll";

import "./NavItem.scss";

const NavItem = ({ to, onClick, shrink, children }) => (
  <Nav.Item>
    <Link
      className={"nav-link cursor-pointer " + (shrink ? "" : "expanded")}
      activeClass="active"
      to={to}
      spy
      offset={-60}
      isDynamic
      smooth="easeInOutQuart"
      onClick={onClick}
    >
      {children || to}
    </Link>
  </Nav.Item>
);

NavItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  shrink: PropTypes.bool
};

NavItem.defaultProps = {
  to: "",
  onClick: null,
  children: null,
  shrink: null
};

export default NavItem;
